import React, { Component } from 'react';

import { observable, computed, reaction,action,decorate } from "mobx";
import { inject, observer, Provider } from "mobx-react";
import { withTranslation, Translation, } from 'react-i18next';

import { connect } from "react-redux";

import { View, Text, Image, FlatList, ActivityIndicator} from "react-native-web";

import PubSub from 'pubsub-js';

import Select from 'react-select';

import Slider from "react-slick";

import { Slide } from 'react-slideshow-image';

import "slick-carousel/slick/slick.css";
//import "slick-carousel/slick/slick-theme.scss";

//import {Link} from "react-router-dom";

import * as Scroll from 'react-scroll';
import { Link, Element , Events, animateScroll as scroll, scrollSpy, scroller } from 'react-scroll';


//import MetaTags from 'react-meta-tags';
//import HeaderPC from "../header/HeaderPC";
import   FooterTablet from "../../footer/FooterTablet";

import i18n from "../../../i18n";


import HamburgerMenu from 'react-hamburger-menu';

import { Fade } from 'react-slideshow-image';


var africaCountries = [
		   { value: 'TG', label: i18n.t('TG') },
		   { value: 'CI', label: i18n.t('CI')},
		   { value: 'GH', label: i18n.t('GH')},
		   { value: 'SN', label: i18n.t('SN')},
		   { value: 'NG', label: i18n.t('NG')}
];

var globalOptions = [
	{
	   label: i18n.t('Africa'),
	   options: africaCountries
	},
	{
	   label: i18n.t('America'),
 	   options: [
 		   { value: 'America_empty',  label: i18n.t('Not_available_yet'), isDisabled: true },
 	   ]
	},
	{
	   label: i18n.t('Asia_and_oceania'),
 	   options: [
 		   { value: 'Asia_empty ', label: i18n.t('Not_available_yet'), isDisabled: true },
 	   ]
	},
	{
	   label: i18n.t('Europe'),
 	   options: [
 		   { value: 'Europe_empty', label: i18n.t('Not_available_yet'), isDisabled: true },
 	   ]
	}

];

const fadeProperties = {
  duration: 3500,
  transitionDuration: 500,
  infinite: true,
  arrows: false,
  indicators: false
}


const viewportWidth = window.innerWidth;
const viewportHeight = window.innerHeight;


function SampleNextArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div  className={className}
      style={{...style, display: "block",position: 'absolute', zIndex: 9, top: '50%', right: '20px' }}
      onClick={onClick}
    />
  );
}

function SamplePrevArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div  className={className}
      style={{ ...style, display: "block",position: 'absolute', zIndex: 9, top: '50%', left: '20px'}}
      onClick={onClick}
    />
  );
}

//@inject("appStore")
//@observer
class HomePageTablet extends Component {

    constructor(props) {

          super(props);



       //   console.log(">>>  store:" + JSON.stringify(store))

		//  store.dispatch("Hello World")

   	 this.state = {
			 displayPopUp: true,
           backgroundColor: "black",
          activeColor: "info",
		selectedOption: null,
		 options: globalOptions,
		 bodyPos: 0,
		open: false,
		 currentYear: new Date().getFullYear()
   	};

     }


	 componentWillMount(){
   	   document.body.style.backgroundImage = "#fff";

	   window.addEventListener("orientationchange", function() {
	   	// Announce the new orientation number
		   window.location.reload();
	   }, false);
	 }

	 componentWillUnmount(){
    	   document.body.style.backgroundImage = null;
	 }



	 handleChange = selectedOption => {

		 console.log(">>> selectedOption:" + selectedOption);

	     this.setState(
	       { selectedOption },
	       () => console.log(`Option selected:`, this.state.selectedOption)
	     );

		 window.location.href = "/" + selectedOption.value.toLowerCase();

	  }

	 handleClick() {
		 if (this.state.open)
		 {
			// var _owidth = '0%';
			 document.getElementById("myNav").style.width = "0%";
			 document.body.style.overflow = 'auto';
			 document.body.style.position = 'relative';
			 window.scrollTo(0, this.state.bodyPos);

		 }
		 else
		 {
		 	//var _owidth = '100%';
			var bodyPos  = window.pageYOffset || document.documentElement.scrollTop;
			 this.setState({
				 bodyPos: bodyPos
			 });
			 document.getElementById("myNav").style.width = "100%";
			 document.body.style.overflow = 'hidden';
			 document.body.style.position = 'fixed';
		 }

	 	this.setState({
	 		open: !this.state.open
	 	});
	 }


     setLocale(lang) {

		   	   console.log("Locale Lang:" +lang)
		         //   this.props.appStore.locale = lang;

		 	 	  localStorage.setItem("language", lang);
		 	 	  i18n.changeLanguage(lang);

		 	   	this.setState({
		 	   		open: false
		 	   	});

		 	    document.getElementById("myNav").style.width = "0%";
		 	    document.body.style.overflow = 'auto';
		 	   document.body.style.position = 'relative';
		 	    window.scrollTo(0, this.state.bodyPos);


	  		  var _options = [], _africaCountries = [];
	  		 setTimeout (function(){
	  			   _africaCountries  = [
	  	 						   { value: 'TG', label: i18n.t('TG') },
	  	 						   { value: 'CI', label: i18n.t('CI')},
	  	 						   { value: 'GH', label: i18n.t('GH')},
	  	 						   { value: 'SN', label: i18n.t('SN')},
	  	 						   { value: 'NG', label: i18n.t('NG')}
	  	 				             ];
	  			   _africaCountries = _africaCountries.sort((a,b) => a.label.localeCompare(b.label));

	  			    _options = [
	  							{
	  							   label: i18n.t('Africa'),
	  							   options: _africaCountries
	  							},
	  							{
	  							   label: i18n.t('America'),
	  						 	   options: [
	  						 		   { value: 'America_empty',  label: i18n.t('Not_available_yet'), isDisabled: true },
	  						 	   ]
	  							},
	  							{
	  							   label: i18n.t('Asia_and_oceania'),
	  						 	   options: [
	  						 		   { value: 'Asia_empty ', label: i18n.t('Not_available_yet'), isDisabled: true },
	  						 	   ]
	  							},
	  							{
	  							   label: i18n.t('Europe'),
	  						 	   options: [
	  						 		   { value: 'Europe_empty', label: i18n.t('Not_available_yet'), isDisabled: true },
	  						 	   ]
	  							}

	  						];
	  	     	           _options = _options.sort((a,b) => a.label.localeCompare(b.label));
	  		  }.bind(this),100);


	   		 setTimeout (function(){
	   			     this.setState({
	   			       options: _options
	   			     });
	   		  }.bind(this),200);
     }


		 closePopUp() {
				this.setState({
					displayPopUp: false
				});
		}


		whatchFilm(t) {
       PubSub.publish('launchVideoPop', t);
    }



  render() {

	  if (viewportWidth  > viewportHeight)
	  {
		  var _n = 3;
	  }
	  else
	  {
	  	var _n = 2;
	  }

	  var settings = {
	    className: "",
	      centerMode: true,
	      infinite: true,
	      centerPadding: "2px",
	      slidesToShow: _n,
	      speed: 500,
		  arrows: true,
	       nextArrow: <SampleNextArrow />,
	        prevArrow: <SamplePrevArrow />
	      };

				let slideImages;

				if (i18n.language == "fr")
				{
						slideImages = [
							{
						    url: 'https://yinitj5.s3.amazonaws.com/cop27/letter_bb_2_tablet_fr.jpg',
						    caption: ''
						  },
							{
						    url: 'https://yinitj5.s3.amazonaws.com/cop27/letter_bb_blurred.jpg',
						    caption: ''
						  },
						  {
						    url: 'https://yinitj5.s3.amazonaws.com/cop27/africans_1.jpg',
						    caption: 'Faire de la COP27 la COP Africaine'
						  },
						  {
						    url: 'https://yinitj5.s3.amazonaws.com/youth_world_pc.jpg',
						    caption: 'Inspirer les jeunes à mener les initiatives de transition énergétique'
						  }
						];
				}
				else
				{
						slideImages = [
							{
						    url: 'https://yinitj5.s3.amazonaws.com/cop27/letter_bb_2_tablet_en.jpg',
						    caption: ''
						  },
							{
						    url: 'https://yinitj5.s3.amazonaws.com/cop27/letter_bb_blurred.jpg',
						    caption: ''
						  },
						  {
						    url: 'https://yinitj5.s3.amazonaws.com/cop27/africans_1.jpg',
						    caption: 'Make the COP27 the African COP'
						  },
						  {
						    url: 'https://yinitj5.s3.amazonaws.com/youth_world_pc.jpg',
						    caption: "Inspire young people to lead the energy transition initiatives"
						  }
						];
				}


    return (
    <Translation>
	    { t =>
	         <div style={{borderWidth: 0, backgroundColor: '#000'}}>






							 <div className="main_header" style={{borderWidth: 0, backgroundColor: '#000'}}>


							 <div style={{position: 'absolute', top: '5px', left: '15px', lineHeight: '60px', zIndex: 9, color: '#fff', fontSize: '17px'}}>
										 <a href="/">
															<img src={'https://d3van47cb9u9tj.cloudfront.net/bilombe/bilombe_rdc_min.png'} style={{height: '60px'}} />
										</a>
							 </div>

							 <div style={{position: 'absolute', top: '5px', left: '86px', lineHeight: '60px', zIndex: 9, color: '#fff', fontSize: '17px'}}>
										 <a href="/">
															<img src={'https://d3van47cb9u9tj.cloudfront.net/bilombe/bilombe_logo_text_min.png'} style={{height: '56px'}} />
										</a>
							 </div>

								<div style={{display: 'none', position: 'absolute', top: '25px', right: '20px', zIndex: 9, color: '#fff', fontSize: '17px'}}>
												 <HamburgerMenu
												 	isOpen={this.state.open}
												 	menuClicked={this.handleClick.bind(this)}
												 	width={35}
												 	height={18}
												 	strokeWidth={1}
												 	rotate={0}
												 	color='black'
												 	borderRadius={0}
												 	animationDuration={0.5}
												 />
								</div>


							</div>



							 <div id="myNav" className="mobileMenuOverlay"
									style={{display: 'none', height: viewportHeight-70, fontFamily: 'Helvetica', background: '#fafafa', textAlign: 'center', paddingTop: '30px', lineHeight: '55px', fontSize: '18px'}}>

										    <div  style={{borderBottom: '1px solid #eee', width: '70%', margin: 'auto'}}>
											   <a href="/">
													{t('Home')}
											     </a>
										    </div>

										 <div  style={{borderBottom: '1px solid #eee', width: '70%', margin: 'auto'}}>
												 <a href="/support">
												    {t('Support')}
													</a>
										 </div>
										 <div  style={{borderBottom: '1px solid #eee', width: '70%', margin: 'auto'}}>
												 <a href="/privacy">
												    {t('Privacy')}
													</a>
										 </div>


									      <div style={{position: 'relative', width: '110px', margin: 'auto', marginTop: '60px'}}>
											   {i18n.language == "fr" &&
												 <div onClick={() => this.setLocale("en")}
												    style={{cursor: 'pointer', position: 'absolute', zIndex: 99, top: '15px', right: '0px', display: 'block', fontSize: '15px', background: 'rgba(255,255,255,0)',
													  borderRadius: '50%', width: '50px', lineHeight: '50px', height: '50px', textAlign: 'center',
													 border: '1px solid #333', color: '#333'}}>
											          EN
											      </div>
											  }
											   {i18n.language == "en" &&
											  <div onClick={() => this.setLocale("en")}
												    style={{cursor: 'pointer', position: 'absolute', zIndex: 99, top: '15px', right: '0px', display: 'block', fontSize: '15px', background: 'rgba(255,255,255,0)',
													  borderRadius: '50%', width: '50px', lineHeight: '50px', height: '50px', textAlign: 'center',
													 border: '1px solid #2489CE', color: '#333'}}>
											          EN
											      </div>
											  }
											   {i18n.language == "fr" &&
											  <div onClick={() => this.setLocale("fr")}
												   style={{cursor: 'pointer', position: 'absolute', zIndex: 99, top: '15px', right: '60px', display: 'block', fontSize: '15px', background: 'rgba(255,255,255,0)',
												   borderRadius: '50%', width: '50px', lineHeight: '50px', height: '50px', textAlign: 'center',
												  border: '1px solid #2489CE', color: '#333'}}>
											             FR
											      </div>
										          }
											   {i18n.language == "en" &&
												  <div onClick={() => this.setLocale("fr")}
												   style={{cursor: 'pointer', position: 'absolute', zIndex: 99, top: '15px', right: '60px', display: 'block', fontSize: '15px', background: 'rgba(255,255,255,0)',
												   borderRadius: '50%', width: '50px', lineHeight: '50px', height: '50px', textAlign: 'center',
												  border: '1px solid #333', color: '#333'}}>
											             FR
											      </div>
										          }
									     </div>
							 </div>




 							    <div className="clear-both" style={{height: '150px', }}/>


									<div style={{position: 'relative', fontFamily: 'Helvetica', textAlign: 'center', margin: 'auto', width: (viewportWidth*0.9)+'px', paddingTop: '30px', paddingBottom: '30px'}}>
	                            <div><img src={'https://d3van47cb9u9tj.cloudfront.net/bilombe/bilombe_rdc_min.png'} style={{height: '300px'}} /></div>

	 													 <div><img src={'https://d3van47cb9u9tj.cloudfront.net/bilombe/bilombe_logo_text_min.png'} style={{width: '320px'}} /></div>


														 <div className="video-responsive" style={{width: (viewportWidth*0.9)+'px', height: (viewportWidth*0.9*9/16)+'px', border: '1px solid #777', margin: 'auto', marginTop: '40px'}}>
													      <iframe
													        width={viewportWidth*0.9}
													        height={viewportWidth*0.9*9/16}
													        src={`https://www.youtube.com/embed/FYYyTxqcZ_k?autoplay=1&mute=1&modestbranding=1&rel=0&autohide=1&showinfo=0`}
													        frameBorder="0"
													        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
													        allowFullScreen
													        title="Bilombe"
													      />
													    </div>

	 													 <div style={{fontSize: '32px', color: '#fff', fontWeight: 'bold', marginTop: '40px'}}>Exposition de l'histoire de la RDCongo, de l'Afrique et de sa diaspora.</div>
	 													 <div style={{fontSize: '23px', color: '#ea980a', marginTop: '25px'}}>L'économie culturelle comme levier de développement.</div>


	 						      </div>




						          <div className="clear-both" style={{height: '170px', }}/>


							       <FooterTablet />

										  <div className="clear-both" style={{height: '80px', }}/>



			        </div>
	  }
	 </Translation>
    )
  }
}


const customStyles = {
  option: (provided, state) => ({
    ...provided,
   // borderBottom: '1px dotted pink',
  //  color: state.isSelected ? 'red' : 'blue',
    paddingLeft: '25px',
	 cursor: state.isDisabled ? 'default' : 'pointer',
  }),
  groupHeading:  (provided) => ({
	  paddingBottom: '5px',
	  color: '#aaa',
	 borderBottom: '1px dotted #ddd',
	  width: '95%',
	  marginLeft: '10px'
   }),
}

const mapStateToProps = state => ({
  ...state
});

const mapDispatchToProps = dispatch => ({

});

export default connect(mapStateToProps, mapDispatchToProps)(HomePageTablet);
