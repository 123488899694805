import React, { Component } from 'react';

import { observable, computed, reaction,action,decorate } from "mobx";
import { inject, observer, Provider } from "mobx-react";
import { withTranslation, Translation, } from 'react-i18next';

import { withRouter } from 'react-router-dom';

import { connect } from "react-redux";

import { View, Text, Image, FlatList, ActivityIndicator} from "react-native-web";

import Select from 'react-select';

import Slider from "react-slick";

import "slick-carousel/slick/slick.css";
//import "slick-carousel/slick/slick-theme.scss";

import { Slide } from 'react-slideshow-image';

//import 'react-slideshow-image/dist/styles.css';


//import {Link} from "react-router-dom";

import * as Scroll from 'react-scroll';
import { Link, Element , Events, animateScroll as scroll, scrollSpy, scroller } from 'react-scroll';



import PubSub from 'pubsub-js';

//import MetaTags from 'react-meta-tags';
//import HeaderPC from "../header/HeaderPC";
import   FooterPC from "../../footer/FooterPC";

import i18n from "../../../i18n";

import { Fade } from 'react-slideshow-image';
//import 'react-slideshow-image/dist/styles.css';

var africaCountries = [
		   { value: 'TG', label: i18n.t('TG') },
		   { value: 'CI', label: i18n.t('CI')},
		   { value: 'GH', label: i18n.t('GH')},
		   { value: 'SN', label: i18n.t('SN')},
		   { value: 'NG', label: i18n.t('NG')}
];

var globalOptions = [
	{
	   label: i18n.t('Africa'),
	   options: africaCountries
	},
	{
	   label: i18n.t('America'),
 	   options: [
 		   { value: 'America_empty',  label: i18n.t('Not_available_yet'), isDisabled: true },
 	   ]
	},
	{
	   label: i18n.t('Asia_and_oceania'),
 	   options: [
 		   { value: 'Asia_empty ', label: i18n.t('Not_available_yet'), isDisabled: true },
 	   ]
	},
	{
	   label: i18n.t('Europe'),
 	   options: [
 		   { value: 'Europe_empty', label: i18n.t('Not_available_yet'), isDisabled: true },
 	   ]
	}

];

const fadeProperties = {
  duration: 3500,
  transitionDuration: 500,
  infinite: true,
  arrows: false,
  indicators: false
}






const viewportWidth = window.innerWidth;
const viewportHeight = window.innerHeight;


function SampleNextArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div  className={className}
      style={{...style, display: "block",position: 'absolute', zIndex: 9, top: '50%', right: '20px' }}
      onClick={onClick}
    />
  );
}

function SamplePrevArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div  className={className}
      style={{ ...style, display: "block",position: 'absolute', zIndex: 9, top: '50%', left: '20px'}}
      onClick={onClick}
    />
  );
}

//@inject("appStore")
//@observer
class HomePagePC extends Component {

    constructor(props) {

          super(props);

   	  // _preview_posts = _preview_posts.sort(function(a,b){return a.createdAtInt-b.createdAtInt});

       //   console.log(">>>  store:" + JSON.stringify(store))

		//  store.dispatch("Hello World")

   	 this.state = {
			 displayPopUp: true,
           backgroundColor: "black",
          activeColor: "info",
		selectedOption: null,
		 options: globalOptions,
		 currentYear: new Date().getFullYear()
   	};

     }


		 componentDidMount(){

		 }

    _componentDidMount(){

			var video = document.getElementById("myVideo");
		 video.play();


	     Events.scrollEvent.register('begin', function(to, element) {
	         //console.log("begin", arguments);
	     });

	     Events.scrollEvent.register('end', function(to, element) {
	       // console.log("end", arguments);
	     });

	     scrollSpy.update();





  		 setTimeout (function(){
  			     this.setState({
  			       options: _options
  			     });

		 		var urlParams = new URLSearchParams(this.props.history.location.search);
		 		console.log(">>> urlParams:" + urlParams);
		 		 console.log(">>> path:" + urlParams.get('m'));
		 		 var query = "";

		 		 if (urlParams.has('m'))
		 		 {
		 			 query = urlParams.get('m');

		 			 if (query == "challenges")
		 			 {
		 				 scroller.scrollTo('menu_challenges', {
		 				   duration: 700,
		 				   delay: 0,
		 				   smooth: true,
		 				   offset: -72
		 				 })
		 			 }
					 else if (query == "youth")
		 			 {
		 				 scroller.scrollTo('menu_youth', {
		 				   duration: 700,
		 				   delay: 0,
		 				   smooth: true,
		 				   offset: -72
		 				 })
		 			 }
					 else if (query == "mission")
		 			 {
		 				 scroller.scrollTo('menu_mission', {
		 				   duration: 700,
		 				   delay: 0,
		 				   smooth: true,
		 				   offset: -72
		 				 })
		 			 }
					 else if (query == "scope")
		 			 {
		 				 scroller.scrollTo('menu_scope', {
		 				   duration: 700,
		 				   delay: 0,
		 				   smooth: true,
		 				   offset: -72
		 				 })
		 			 }

		 		 }


  		  }.bind(this),500);




		 var _options = [], _africaCountries  = [];

		 setTimeout (function(){
			   _africaCountries  = [
	 						   { value: 'TG', label: i18n.t('TG') },
	 						   { value: 'CI', label: i18n.t('CI')},
	 						   { value: 'GH', label: i18n.t('GH')},
	 						   { value: 'SN', label: i18n.t('SN')},
	 						   { value: 'NG', label: i18n.t('NG')}
	 				             ];
			   _africaCountries = _africaCountries.sort((a,b) => a.label.localeCompare(b.label));

			    _options = [
							{
							   label: i18n.t('Africa'),
								options: _africaCountries
							},
							{
							   label: i18n.t('America'),
						 	   options: [
						 		   { value: 'America_empty',  label: i18n.t('Not_available_yet'), isDisabled: true },
						 	   ]
							},
							{
							   label: i18n.t('Asia_and_oceania'),
						 	   options: [
						 		   { value: 'Asia_empty ', label: i18n.t('Not_available_yet'), isDisabled: true },
						 	   ]
							},
							{
							   label: i18n.t('Europe'),
						 	   options: [
						 		   { value: 'Europe_empty', label: i18n.t('Not_available_yet'), isDisabled: true },
						 	   ]
							}

						];
	     	           _options = _options.sort((a,b) => a.label.localeCompare(b.label));

			  //   _options = _options.sort(nestedSort("moreDetails", "age", "desc"));
		  }.bind(this),1200);

  		 setTimeout (function(){
  			     this.setState({
  			       options: _options
  			     });

  		  }.bind(this),1600);




		 // _jsonArrayNonActiveContacts.sort((a,b) => a.localeName.localeCompare(b.localeName));
	}


	 componentWillMount(){
   	   document.body.style.backgroundImage = "#fff";
	 }

	 componentWillUnmount(){
    	      document.body.style.backgroundImage = null;

		Events.scrollEvent.remove('begin');
		Events.scrollEvent.remove('end');
	 }




	 handleChange = selectedOption => {

		 console.log(">>> selectedOption:" + selectedOption);

	     this.setState(
	       { selectedOption },
	       () => console.log(`Option selected:`, this.state.selectedOption)
	     );

		 window.location.href = "/" + selectedOption.value.toLowerCase();

	  }


    setLocale(lang) {

  	  console.log("Locale Lang:" +lang)
        //   this.props.appStore.locale = lang;

	 	  localStorage.setItem("language", lang);
	 	  i18n.changeLanguage(lang);

		 /* if (lang == "fr")
		  {
			  options = optionsFR;
		  }
		  else
		  {
		  	options = optionsEN;
		  }

		   options = options.sort((a,b) => a.label.localeCompare(b.label)); */
		  var _options = [], _africaCountries = [];
		 setTimeout (function(){
			   _africaCountries  = [
	 						   { value: 'TG', label: i18n.t('TG') },
	 						   { value: 'CI', label: i18n.t('CI')},
	 						   { value: 'GH', label: i18n.t('GH')},
	 						   { value: 'SN', label: i18n.t('SN')},
	 						   { value: 'NG', label: i18n.t('NG')}
	 				             ];
			   _africaCountries = _africaCountries.sort((a,b) => a.label.localeCompare(b.label));

			    _options = [
							{
							   label: i18n.t('Africa'),
							   options: _africaCountries
							},
							{
							   label: i18n.t('America'),
						 	   options: [
						 		   { value: 'America_empty',  label: i18n.t('Not_available_yet'), isDisabled: true },
						 	   ]
							},
							{
							   label: i18n.t('Asia_and_oceania'),
						 	   options: [
						 		   { value: 'Asia_empty ', label: i18n.t('Not_available_yet'), isDisabled: true },
						 	   ]
							},
							{
							   label: i18n.t('Europe'),
						 	   options: [
						 		   { value: 'Europe_empty', label: i18n.t('Not_available_yet'), isDisabled: true },
						 	   ]
							}

						];
	     	           _options = _options.sort((a,b) => a.label.localeCompare(b.label));
		  }.bind(this),100);


 		 setTimeout (function(){
 			     this.setState({
 			       options: _options
 			     });
 		  }.bind(this),200);




    }


	goTo(pos) {
		 console.log(">>> Go to:  " +pos)
		if (pos == "challenges")
		{
			//window.scrollTo(0, this.menuChallengesRef.offsetTop-70, behavior: 'smooth');
		}
	}


   closePopUp() {
		   this.setState({
				 displayPopUp: false
			 });
	 }


	 whatchFilm(t) {
			PubSub.publish('launchVideoPop', t);
	 }




  render() {

	    var _n = 3;

	  var _settings = {
	    className: "",
	      centerMode: true,
	      infinite: true,
	      centerPadding: "2px",
	      slidesToShow: _n,
	      speed: 500,
		  arrows: true,
	       nextArrow: <SampleNextArrow />,
	        prevArrow: <SamplePrevArrow />
	      };

				const images = [
			    "https://yinitj5.s3.amazonaws.com/cop27/africans_1.jpg",
			    "https://yinitj5.s3.amazonaws.com/youth_world_pc.jpg",
			    "https://yinitj5.s3.amazonaws.com/cop27/global_warming_2.jpg",
			  ];

				let slideImages;
				let _url;

				if (i18n.language == "fr")
				{
					  if (viewportWidth < 1600)
						{
							 _url = 'https://yinitj5.s3.amazonaws.com/cop27/letter_bb_2_fr_2.jpg';
						}
						else
						{
							 _url = 'https://yinitj5.s3.amazonaws.com/cop27/letter_bb_2_fr.jpg';
						}

						slideImages = [
							{
						    url: _url,
						    caption: ''
						  },
							{
						    url: 'https://yinitj5.s3.amazonaws.com/cop27/letter_bb_blurred.jpg',
						    caption: ''
						  },
						  {
						    url: 'https://yinitj5.s3.amazonaws.com/cop27/africans_1.jpg',
						    caption: 'Faire de la COP27 la COP Africaine'
						  },
						  {
						    url: 'https://yinitj5.s3.amazonaws.com/youth_world_pc.jpg',
						    caption: 'Inspirer les jeunes à mener les initiatives de transition énergétique'
						  }
						];
				}
				else
				{

							if (viewportWidth < 1600)
							{
								 _url = 'https://yinitj5.s3.amazonaws.com/cop27/letter_bb_2_en_2.jpg';
							}
							else
							{
								 _url = 'https://yinitj5.s3.amazonaws.com/cop27/letter_bb_2_en.jpg';
							}

						slideImages = [
							{
						    url: _url,
						    caption: ''
						  },
							{
						    url: 'https://yinitj5.s3.amazonaws.com/cop27/letter_bb_blurred.jpg',
						    caption: ''
						  },
						  {
						    url: 'https://yinitj5.s3.amazonaws.com/cop27/africans_1.jpg',
						    caption: 'Make the COP27 the African COP'
						  },
						  {
						    url: 'https://yinitj5.s3.amazonaws.com/youth_world_pc.jpg',
						    caption: "Inspire young people to lead the energy transition initiatives"
						  }
						];
				}



    return (
    <Translation>
	    { t =>
	         <div>






			         <div className="main_header" style={{borderWidth: 0}}>

								<div style={{position: 'relative', background: '#000', width: '100%', height: '70px'}}>

									  <div style={{position: 'absolute', top: '5px', left: '15px', lineHeight: '60px', zIndex: 9, color: '#fff', fontSize: '17px'}}>
									        <a href="/">
				                           <img src={'https://d3van47cb9u9tj.cloudfront.net/bilombe/bilombe_rdc_min.png'} style={{height: '60px'}} />
										     </a>
									  </div>

										<div style={{position: 'absolute', top: '5px', left: '86px', lineHeight: '60px', zIndex: 9, color: '#fff', fontSize: '17px'}}>
									        <a href="/">
				                           <img src={'https://d3van47cb9u9tj.cloudfront.net/bilombe/bilombe_logo_text_min.png'} style={{height: '56px'}} />
										     </a>
									  </div>

									  <div className="main_header_link" style={{display: 'none', width: '1000px', margin: 'auto', textAlign: 'center', lineHeight: '70px', fontSize: '18px', fontFamily: 'Lato'}}>
										   <Link activeClass="activeMenuLink" className="menu_link" to="menu_challenges" spy={true} smooth={true} offset={-72} duration={700} delay={0}>
												{t('The_challenges')}
										     </Link>
										   <Link activeClass="activeMenuLink" className="menu_link" to="menu_youth" spy={true} smooth={true} offset={-72} duration={700} delay={0}>
												{t('Why_youth')}
										     </Link>
										   <Link activeClass="activeMenuLink" className="menu_link"  to="menu_mission" spy={true} smooth={true} offset={-72} duration={700} delay={0}>
												{t('Mission_and_vision')}
										     </Link>
										   <Link activeClass="activeMenuLink" className="menu_link" to="menu_scope" spy={true} smooth={true} offset={-72} duration={700} delay={0}>
												{t('Scope_of_action')}
										     </Link>
									  </div>

									   {1===2 && i18n.language == "fr" &&
										 <div onClick={() => this.setLocale("en")}
										    style={{cursor: 'pointer', position: 'absolute', zIndex: 99, top: '15px', right: '15px', display: 'block', fontSize: '13px', background: 'rgba(255,255,255,0)',
											  borderRadius: '50%', width: '40px', lineHeight: '40px', height: '40px', textAlign: 'center',
											 border: '1px solid #333', color: '#333', fontFamily: 'Lato'}}>
									          EN
									      </div>
									  }
									   {1===2 && i18n.language == "en" &&
									  <div onClick={() => this.setLocale("en")}
										    style={{cursor: 'pointer', position: 'absolute', zIndex: 99, top: '15px', right: '15px', display: 'block', fontSize: '13px', background: 'rgba(255,255,255,0)',
											  borderRadius: '50%', width: '40px', lineHeight: '40px', height: '40px', textAlign: 'center',
											 border: '1px solid #2489CE', color: '#333', fontFamily: 'Lato'}}>
									          EN
									      </div>
									  }
									   {1===2 && i18n.language == "fr" &&
									  <div onClick={() => this.setLocale("fr")}
										   style={{cursor: 'pointer', position: 'absolute', zIndex: 99, top: '15px', right: '65px', display: 'block', fontSize: '13px', background: 'rgba(255,255,255,0)',
										   borderRadius: '50%', width: '40px', lineHeight: '40px', height: '40px', textAlign: 'center',
										  border: '1px solid #2489CE', color: '#333', fontFamily: 'Lato'}}>
									             FR
									      </div>
								          }
									   {1===2 && i18n.language == "en" &&
										  <div onClick={() => this.setLocale("fr")}
										   style={{cursor: 'pointer', position: 'absolute', zIndex: 99, top: '15px', right: '65px', display: 'block', fontSize: '13px', background: 'rgba(255,255,255,0)',
										   borderRadius: '50%', width: '40px', lineHeight: '40px', height: '40px', textAlign: 'center',
										  border: '1px solid #333', color: '#333', fontFamily: 'Lato'}}>
									             FR
									      </div>
								          }

								</div>

          		 </div>




							 <div className="clear-both" style={{height: '70px', }}/>



						   <div style={{position: 'relative', fontFamily: 'Helvetica', textAlign: 'center', margin: 'auto', width: (viewportHeight*0.7*16/9)+'px', paddingTop: '30px', paddingBottom: '30px'}}>
                           <div><img src={'https://d3van47cb9u9tj.cloudfront.net/bilombe/bilombe_rdc_min.png'} style={{height: '360px'}} /></div>

													 <div><img src={'https://d3van47cb9u9tj.cloudfront.net/bilombe/bilombe_logo_text_min.png'} style={{width: '360px'}} /></div>



													 <div className="video-responsive" style={{width: (viewportHeight*0.7*16/9)+'px', height: (viewportHeight*0.7)+'px', border: '1px solid #777', margin: 'auto', marginTop: '40px'}}>
												      <iframe
												        width={viewportHeight*0.7*16/9}
												        height={viewportHeight*0.7}
												        src={`https://www.youtube.com/embed/FYYyTxqcZ_k?autoplay=1&mute=1&modestbranding=1&rel=0&autohide=1&showinfo=0`}
												        frameBorder="0"
												        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
												        allowFullScreen
												        title="Bilombe"
												      />
												    </div>

													 <div style={{width: '800px', margin: 'auto', fontSize: '32px', color: '#fff', fontWeight: 'bold', marginTop: '60px'}}>
													     Exposition de l'histoire de la RDCongo, de l'Afrique et de sa diaspora.
													</div>
													 <div style={{width: '800px', margin: 'auto', fontSize: '23px', color: '#ea980a', marginTop: '25px'}}>
													   L'économie culturelle comme levier de développement.
													</div>


						      </div>

						    	<div className="clear-both" style={{height: '30px', }}/>



							 <FooterPC />



			        </div>
	  }
	 </Translation>
    )
  }
}


const customStyles = {
  option: (provided, state) => ({
    ...provided,
   // borderBottom: '1px dotted pink',
  //  color: state.isSelected ? 'red' : 'blue',
    paddingLeft: '25px',
	 cursor: state.isDisabled ? 'default' : 'pointer',
  }),
  groupHeading:  (provided) => ({
	  paddingBottom: '5px',
	  color: '#aaa',
	 borderBottom: '1px dotted #ddd',
	  width: '95%',
	  marginLeft: '10px'
   }),
}


const mapStateToProps = state => ({
  ...state
});

const mapDispatchToProps = dispatch => ({

});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(HomePagePC));
