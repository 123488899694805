import React,{ Component } from 'react';
import { BrowserRouter, Route, Link, Switch, withRouter, Redirect  } from 'react-router-dom';
import { connect } from 'react-redux';
import { withTranslation, Translation, } from 'react-i18next';
import PropTypes from 'prop-types';

import { sessionService } from 'redux-react-session';

import { Text, View } from 'react-native-web';


import HomePage from "./components/pages/home/HomePage";
import PrivacyPage from "./components/pages/general/PrivacyPage";
import TermsPage from "./components/pages/general/TermsPage";
import EulaPage from "./components/pages/general/EulaPage";
import SupportPage from "./components/pages/general/SupportPage";



import ErrorPageNotFound from "./components/pages/error/ErrorPageNotFound";


import DropdownMenu from "./components/header/DropdownMenu";
import DropdownMenuItem from "./components/header/DropdownMenuItem";



import setLanguage from "./actions/setLanguage";
import setUserConnection from "./actions/setUserConnection";

import PubSub from 'pubsub-js';

import {BrowserView,MobileView,TabletView,isBrowser,isMobileOnly, isTablet} from 'react-device-detect';



import { isLogin, profileExists, isReservedPath } from './utils';

import i18n from "./i18n";

import {generateIPForREST} from "./actions";

const viewportWidth = window.innerWidth;
const viewportHeight = window.innerHeight;

class App extends Component {

    constructor(props) {

          super(props);



   	 this.state = {
       displayVideoPop: false,
       videoID: null,
   	 };

     }


     componentWillUnmount(){


        //  PubSub.unsubscribe(this.launchVideoPopVar);


   }





    componentDidMount() {

    //  document.addEventListener('mousedown', this.handleClickOutsideVideoPop);


		// this.launchVideoPopVar = PubSub.subscribe('launchVideoPop', this.onLaunchVideoPop.bind(this));



		fetch('https://api.ipstack.com/check?access_key=022203c728a84a2d30f048b35db24a6e')
		  	.then((response) => response.json())
		  	.then((responseData) => {
				// alert("App. Store: " + JSON.stringify(responseData));

				  localStorage.setItem("geoLocation", responseData);
          localStorage.setItem("country_code", responseData.country_code);

				  if (isLogin())
				  {

  					  if (localStorage.getItem('language') === null)
  					  {
  						  //this.getLanguageFromServer(responseData.country_code);
                this.props.setLanguage('US');

  					  }
  					  else
  					  {
  						   var _lang = localStorage.getItem('language');
  						    console.log("Got language from localStore: " + _lang);
  					  	  i18n.changeLanguage(_lang);
  					  }
				  }
				  else
				  {

                if (localStorage.getItem('language') === null)
    					  {

                   this.props.setLanguage(responseData.country_code);
    					     this.props.setUserConnection({ip: responseData.ip, connection_country: responseData.country_code, connection_city: responseData.city});

    					  }
    					  else
    					  {

    						    var _lang = localStorage.getItem('language');
    						    //alert("Got language from localStore: " + _lang);
    					  	  i18n.changeLanguage(_lang);
    					  }



				  }


				  // .country_code
		      })
		     .catch(function(error) {
			          console.log(error);

				   var _country_code  = "US", _lang = 'en';

  				  if (1==2 && isLogin())
  				  {
      					  if (localStorage.getItem('language') === null)
      					  {

    						    // this.getLanguageFromServer(_country_code);
                      this.props.setLanguage('US');
      					  }
      					  else
      					  {
      						  var _lang = localStorage.getItem('language');
      						  console.log("Got language from localStore: " + _lang);
      					  	  i18n.changeLanguage(_lang);
      					  }
  				  }
  				  else
  				  {
                  if (localStorage.getItem('language') === null)
                 {

                   // this.getLanguageFromServer(_country_code);
                      this.props.setLanguage('US');
                 }
                 else
                 {
                   var _lang = localStorage.getItem('language');
                   console.log("Got language from localStore: " + _lang);
                     i18n.changeLanguage(_lang);
                 }
  				  }


		     }.bind(this));
		   /*.catch((error) => {

		   })
		   .done();*/
	/*	console.log("App. Store: " + JSON.stringify(this.props));

		    this.props.setBgAction("white");

			setTimeout (function(){
				console.log(">>>>2. App. Store: " + JSON.stringify(this.props));
			}.bind(this),3000);*/
    }


	getLanguageFromServer(_country_code) {
		//var ip = "https://www.debyoo.com/";
		var ip = generateIPForREST();

		var jwtToken = localStorage.getItem("jwtToken") || '';
		//  this.props.setLanguage(responseData.country_code);
		fetch(ip+'get_language_from_server_api', {
                              method: 'POST',
                              headers: {
                                'Accept': 'application/json',
				    'Authorization': jwtToken,
                                'Content-Type': 'application/json'
                              },
				   credentials: 'include',
                              body: JSON.stringify({
                              })
                      })
		  	.then((response) => response.json())
		  	.then((responseData) => {
				console.log("Got language from server: " + responseData.language);
				 i18n.changeLanguage(responseData.language);
	             })
	            .catch(function(error) {
				     this.props.setLanguage(_country_code);
		     }.bind(this));
	}




  handleClickOutsideVideoPop(event) {
    if (isBrowser)
    {
        setTimeout (function(){
          if (this.videoPopWrapperRef && this.videoPopWrapperRef.current !== null && !this.videoPopWrapperRef.current.contains(event.target)) {
            this.setState({
              displayVideoPop: false,
              videoID: null
            });
          }
        }.bind(this), 100);
   }

  }

  onLaunchVideoPop = (msg,data) => {

   // alert(data)

     this.setState({
       displayVideoPop: true,
       videoID: data
     });
  }


  showVideoPop() {
   this.setState({
     displayVideoPop: true
   });
 }

 closeVideoPop() {


   this.setState({
     displayVideoPop: false,
     videoID: null
   });
 }





    render () {




	let store = this.props.store;
	const { history } = this.props;

	console.log("history: " + JSON.stringify(history))

        return (
	         <Translation>
			{
			        t =>
	           <BrowserRouter>
			          <div className="app_main_wrapper">







					       

					        <Route path="/" exact render={props => <HomePage store={this.props.store} path={props.location.pathname}/>} />







				        </div>
				    </BrowserRouter>
	                    }
	           </Translation>
        );
    }
};


const mapStateToProps = state => ({
  ...state
});

const mapDispatchToProps = dispatch => ({
  setLanguage:  (payload) => dispatch(setLanguage(payload)),
   setUserConnection:  (payload) => dispatch(setUserConnection(payload))
});

export default connect(mapStateToProps, mapDispatchToProps)(App);
